<template>
  <base-form-item :field="field">
    <div v-if="value && value.length > 0">
      <table class="table">
        <thead>
        <tr>
          <th class="table--th-view">{{ $t('issues.subject') }}</th>
          <th class="table--th-view">{{ $t('issues.status_id') }}</th>
          <th class="table--th-view">{{ $t('issues.assigned_to') }}</th>
          <th class="table--th-view">{{ $t('issues.done') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <tr
            v-bind:key="item.id"
            v-for="item in value"
        >
          <td
              @click="showChildIssue(item.id)"
              class="cursor-pointer table--tbody pr-2 pl-2"
          >
            <div class="table--div-deleteWrap">
              {{ item.subject }}
            </div>
          </td>
          <td class="cursor-pointer table--tbody pr-2 pl-2">{{
              item.status.enumeration ? item.status.enumeration.name :
              ''
                                                            }}
          </td>
          <td class="cursor-pointer table--tbody pr-2 pl-2">{{ item.users.length > 0 ? item.users[0].name : '' }}</td>
          <td class="cursor-pointer custom--progress table--tbody pr-2 pl-2">
            <div class=" d-flex align-items-center">
              <el-progress
                  :color="getStatusColor(value)"
                  :percentage="parseInt(item.done_ratio)"
                  :show-text="false"
                  :style="[{'border-color' : getStatusColor(value)}]"
              />
              <span
                  class="px-1"
                  style="min-width: 40px;"
              >{{ item.done_ratio }}%</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  name: 'index',
  mixins: [abstractForm],
  components: {BaseFormItem},
  computed: {
    ...mapGetters(['systemSetting']),
  },
  methods: {
    showChildIssue(id) {
      let query = {...this.$route.query};
      query.issueId = id;
      this.$router.push({path: this.$route.path, query: query});
    },
    days_left: function (date) {
      return moment(date).diff(moment(), 'days');
    },
    getStatusColor(date) {
      const now = moment().format('YYYY-MM-DD');
      const dueDate = moment(date).format('YYYY-MM-DD');
      const diff = this.days_left(date);
      if (now > dueDate) {
        return this.systemSetting.due_date_color;
      }
      if (now < dueDate) {
        if (diff < this.systemSetting.days_ago) return this.systemSetting.priority_color;
        return 'green';
      }
      return '';
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../../../../static/css/table';
</style>
